import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"
import { fieldsAll } from "ui/util"
import ImageList from "ui/ImageList"
import Field from "ui/Field"

const Block = ({context, _path, fieldPath, fieldEntity, block, config, ...props}) => {
    const ref = useRef()


    const newContext = useMemo(() => ({
        ...context,
        block,
    }), [context, block])
    const newPath = useAdmin(ref, newContext, _path, fieldPath, fieldEntity, block, "Block")
    const sf0 = {}
    useWidget(ref, config)

    return (
        <div {...props} ref={ref}  elem-block="" type-block="">
            {config?.bg && (<ImageList context={newContext} _path={newPath} bg="1" className="block-bg" imageStyle={config?.bgStyle ?? "img"} fieldPath="bg" img={config?.bg} elem-img="" type-img="" fieldEntity={block}/>)}
            <div block-inner="">
                {fieldsAll(block, sf0, context.language)?.map((item, i) => (<Field context={newContext} _path={newPath} fieldPath={item.key} fieldEntity={block} value={item.value} type={item.type} config={item._e} key={i} _index={i}/>))}
            </div>
        </div>
    )
}
export default Block
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {}
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "block.bg"
          ],
          "attr": {
            "if": [
              "f",
              "block.bg"
            ],
            "_e": {
              "if": {
                "_type": "op",
                "is": "exprValExt"
              },
              "bg": {
                "is": "exprValExt"
              },
              "className": {
                "is": "exprValExt"
              },
              "imageStyle": {
                "1": {
                  "_type": "op"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "bg": "1",
            "className": "block-bg",
            "imageStyle": [
              "??",
              [
                "f",
                "block.bgStyle"
              ],
              "img"
            ]
          }
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "block-inner": ""
          }
        },
        "children": [
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "op"
                }
              },
              "name": [
                "f",
                "e._all_"
              ]
            }
          }
        ]
      }
    ]
  }
}
*/
