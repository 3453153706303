import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"
import Slider from "ui/Slider"
import ItemTransition from "ui/ItemTransition"
import ImageList from "ui/ImageList"

const Slideshow = ({context, _path, fieldPath, fieldEntity, slideshow, config, ...props}) => {
    const ref = useRef()


    const newContext = useMemo(() => ({
        ...context,
        slideshow,
    }), [context, slideshow])
    const newPath = useAdmin(ref, newContext, _path, fieldPath, fieldEntity, slideshow, "Slideshow")
    useWidget(ref, config)

    return (
        <div {...props} ref={ref}  elem-slideshow="" type-slideshow="">
            <Slider context={newContext} _path={newPath} items={slideshow}>{(newContext, activeSlide, active) => (<>
                <ItemTransition context={newContext} _path={newPath} itemKey={newContext.activeSlide?.img?.[0]?.url}>
                    <ImageList context={newContext} _path={newPath} bg="1" imageStyle="slide" fieldPath="img" img={newContext.activeSlide?.img} elem-img="" type-img="" fieldEntity={activeSlide}/>
                </ItemTransition>
            </>)}</Slider>
            <div className="text">
                <h1>Panoramic Padel Court by</h1>
                <h2>Aegean Padel Courts</h2>
            </div>
        </div>
    )
}
export default Slideshow
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Slider",
          "attr": {
            "items": [
              "f",
              "slideshow"
            ],
            "_e": {
              "items": {
                "_type": "op",
                "is": "exprValExt"
              }
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "ItemTransition",
              "attr": {
                "itemKey": [
                  "get",
                  "activeSlide",
                  "img.0.url"
                ],
                "_e": {
                  "itemKey": {
                    "_type": "op",
                    "is": "exprValExt"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "_e": {
                    "name": {
                      "_type": "op"
                    }
                  },
                  "name": [
                    "get",
                    "activeSlide",
                    "img"
                  ],
                  "attr": {
                    "bg": "1",
                    "_e": {
                      "bg": {
                        "is": "exprValExt"
                      },
                      "imageStyle": {
                        "is": "exprValExt"
                      }
                    },
                    "imageStyle": "slide"
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "text"
          }
        },
        "children": [
          {
            "data": {
              "name": "h1",
              "attr": {
                "content": "Panoramic Padel Court by",
                "_e": {
                  "content": {
                    "is": "exprValExt"
                  }
                }
              }
            }
          },
          {
            "data": {
              "name": "h2",
              "attr": {
                "content": "Aegean Padel Courts",
                "_e": {
                  "content": {
                    "is": "exprValExt"
                  }
                }
              }
            }
          }
        ]
      }
    ]
  }
}
*/
