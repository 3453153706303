import React from "react"
import Portal from "./Portal"
import ItemTransition from "./ItemTransition"
//import { useSubscription } from "../hooks/useSubscription"
import { useHotkey } from "../hooks/useHotkey"

const Lightbox = ({ onClose, children, ...props }) => {
    const [open, setOpen] = React.useState(true)
    /*useSubscription("closeLightbox", () => {
        setLB(false)
    })*/
    const transitionProps = React.useMemo(
        () => ({
            onExited: () => {
                onClose()
            },
        }),
        [onClose]
    )
    const handleClose = React.useCallback(() => {
        setOpen("none")
    }, [])
    React.useEffect(() => {
        if(open)
            document.body.classList.add("modal-open")
        else
            document.body.classList.remove("modal-open")
        return () => {
            document.body.classList.remove("modal-open")
        }
    }, [open])
    useHotkey("Escape", handleClose)
    //console.log("Lightbox", children, props)
    return (
        <Portal>
            <ItemTransition itemKey={open} className="lightbox" transitionProps={transitionProps}>
                <div className="overlay" onClick={handleClose} />
                <div className="lightbox-content" onClick={handleClose}>
                    {children}
                </div>
            </ItemTransition>
        </Portal>
    )
}
export default Lightbox
