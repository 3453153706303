const chapters = new WeakMap()

const speed = (p, max, pad=0) => {
    if(p<pad) return max
    if(p>100-pad) return -max
    const delta = 50-pad
    return -(p - pad - delta)/delta*max
}
const parallax = (e, p, max, pad) => {
    if(!e) return
    e.style.transform = `translate3d(0, ${speed(p, max, pad)}px, 0)`
}

const handleChapterScroll = (elem, scroll, dir, top, height, wh, ww) => {
    if(ww<800) return
    let elements = chapters.get(elem)
    if(!elements) {
        elements = {
            header: elem.querySelector(".chapter-header"),
            text: elem.querySelector(".text-content"),
            bg: elem.querySelector(".block-bg"),
            bg2: elem.querySelector(".content .block-bg"),
            img: elem.querySelector(".content [type-img]"),
        }
        //console.log(elements.bg,elements.bg2, elements.bg===elements.bg2)
        if (elements.bg2) {
            if(elements.bg===elements.bg2)
                delete elements.bg
            delete elements.img
        }
        //console.log(elem, elements)
        chapters.set(elem, elements)
    }
    //const rect = elem.getBoundingClientRect()
    //const top = rect.top + window.scrollY
    //const height = rect.height
    const p = (scroll-top+wh)*100/(height + wh)
    parallax(elements.header, p, -8*wh/100)
    parallax(elements.text, p, 33*wh/100)
    parallax(elements.img, p, 20*wh/100)
    parallax(elements.bg, p, -45*wh/100)
    parallax(elements.bg2, p, -20*wh/100)
    /*
    if(elements.text) {
        const t = speed(p, 300)
        elements.text.style.transform = `translate3d(0, ${t}px, 0)`
    }
    if(elements.img) {
        const t = -speed(p, 200)
        elements.img.style.transform = `translate3d(0, ${t}px, 0)`
    }
    if(elements.bg) {
        const t = -speed(p, 400)
        elements.bg.style.transform = `translate3d(0, ${t}px, 0)`
    }
    if(elements.bg2) {
        const t = -speed(p, 200)
        elements.bg2.style.transform = `translate3d(0, ${t}px, 0)`
    }
    */
    //console.log("DIV", elements, scroll, top, height, wh)
}

const handleOverviewScroll = (elem, scroll, dir, top, height, wh, ww) => {
    if(ww<800) return
    let elements = chapters.get(elem)
    if(!elements) {
        elements = {
            //header: elem.querySelector(".chapter-header"),
            //text: elem.querySelector(".text-content"),
            bg: elem.querySelector(".block-bg"),
            //bg2: elem.querySelector(".content .block-bg"),
            //img: elem.querySelector(".content [type-img]"),
        }
        //console.log(elements.bg,elements.bg2, elements.bg===elements.bg2)
        /*if (elements.bg2) {
            if(elements.bg===elements.bg2)
                delete elements.bg
            delete elements.img
        }*/
        //console.log(elem, elements)
        chapters.set(elem, elements)
    }
    //const rect = elem.getBoundingClientRect()
    //const top = rect.top + window.scrollY
    //const height = rect.height
    const p = (scroll-top+wh)*100/(height + wh)
    //parallax(elements.header, p, -75)
    //parallax(elements.text, p, 300)
    //parallax(elements.img, p, 200)
    parallax(elements.bg, p, -45*wh/100)
    //parallax(elements.bg2, p, -200)
}
export const handlers = {handleChapterScroll, handleOverviewScroll}
    
