import React from "react"
//import ReactDOM from "react-dom"
//import { useUnmounted } from "../hooks/useUnmounted"
import Lightbox from "./Lightbox"

const WithLightbox = ({ context, lightbox, children }) => {
    //const [renderLightbox, props] = useLightbox(context, lightbox, lightboxParent, field)
    const [lb, setLB] = React.useState()
    const handleClick = React.useCallback(() => {
        //console.log("click")
        setLB(true)
    }, [])
    const handleClose = React.useCallback(() => {
        setLB(false)
    }, [])
    const props = { onClick: handleClick }
    return (
        <>
            {children(props)}
            {lb && <Lightbox onClose={handleClose}>{lightbox}</Lightbox>}
        </>
    )
}
export default WithLightbox
