import React from "react"
import LazyImage from "./widgets/LazyImage"
import { useAdmin } from "./hooks/useAdmin"
import WithLightbox from "./WithLightbox"
import Carousel from "./Carousel"
import Track from "./Track"
import Arrows from "./Arrows"
import Navigator from "./Navigator"

const Img = props => {
    const ref = React.useRef()
    const { context, _path, fieldPath, fieldEntity, img, config, _wrap, ...attrs } = props
    useAdmin(ref, context, _path, fieldPath, fieldEntity, img)
    if(config?.lightbox)
        return (
            <WithLightbox lightbox={<LazyImage {...attrs} />}>
                {props => <LazyImage domRef={ref} {...attrs} {...props}/>}
            </WithLightbox>
        )
    return (
        <LazyImage domRef={ref} config={config} {...attrs} />
    )
}

const LightboxCarousel = props => {
    const { active, context, _path, fieldPath, fieldEntity, img, config, _wrap, ...attrs } = props
    const newConfig = React.useMemo(() => ({...config??{}, active}), [config, active])
    return (
        <div className="lightbox-carousel">
            <Carousel config={newConfig}>{(context, carousel) => (
                <>
            <Track context={context} carousel={carousel}>
                {img?.map((src, i) => (
                    <LazyImage key={i} src={src} config={config} _index={i} />
                ))}
            </Track>
                    <Navigator carousel={carousel}/>
                    <Arrows carousel={carousel}/>
                    </>
        )}
        </Carousel>
            </div>
    )
}

const ImgWrap = props => {
    const { context, _path, fieldPath, fieldEntity, img, config, _wrap, ...attrs } = props
    const ref = React.useRef()
    //console.log("ImageList", fieldPath, fieldEntity, img)
    useAdmin(ref, context, _path, fieldPath, fieldEntity, img)

    return (
        <div ref={ref} {...attrs}>
            {img?.map((src, i) => (
                config?.lightbox
                    ?<WithLightbox key={i}
                                   lightbox={<LightboxCarousel {...props} active={i}/>}
                     >{props => <LazyImage key={i} src={src} config={config} _index={i} {...props}/>}</WithLightbox>
                :<LazyImage key={i} src={src} config={config} _index={i} />
            ))}
            </div>
    )
}



const ImageList = props => {
    const { context, _path, fieldPath, fieldEntity, img, config, _wrap, ...attrs } = props
    //console.log("ImageList", fieldPath, fieldEntity, img, config)
    //const [ref] = useAdmin(context, _path, fieldPath, fieldEntity, img, img?.length)
    //let Tag = React.Fragment
    //let tagArgs = {}
    //let imgProps = attrs
    //let iimgProps = []
    
    if (config?._wrap) {
        return <ImgWrap {...props}/>
        /*Tag = "div"
        tagArgs = attrs
        tagArgs['ref'] = ref
        imgProps = {}*/
        //const { domRef, inline, _e, ...rest } = htmlAttrs
        //tagArgs = { ref: domRef, ...rest, "type-img": "" }
        //imgArgs = { inline, _e }
    }
    return (
        <>
            {img?.map((src, i) => (<Img key={i} {...props} src={src} _index={i}/>))}
            </>
    )
    //else {
        //iimgProps = [{domRef: ref}]
        //imgArgs["type-img"] = ""
    //}
    //console.log(tagArgs, imgProps, img)
    /*return (
        <Tag {...tagArgs}>
            {img?.map((src, i) => (
                <LazyImage key={i} src={src} {...(iimgProps[i]??{})} {...imgProps} _index={i} />
            ))}
        </Tag>
    )*/
}

/*
import { useSubscription } from "../hooks/useSubscription"
import Portal from "./Portal"

import { useAttrs } from "./hooks/useAttrs"

//const Element = () => <div />
const ImageList = attrs => {
    const { context, state, dispatch, config } = attrs
    const htmlAttrs = useAttrs(attrs)
    //console.log("IMAGE LIST", info, attrs)
    const [lb, setLB] = React.useState()
    //const carouselInfo = info.typeInfo._carousel
    let val = context.e
    //console.log(attrs)
    //const typeInfo = info.context._e.e
    useSubscription("closeLightbox", () => {
        setLB(false)
    })
    const [, lightboxInfo] = React.useMemo(() => {
        return [null, null]
        
        if (!attrs.lightbox) return [null, null]
        const attrsInfo = Entity.getType(attrs, { is: "map", values: "exprValExt" })
        const lightboxType = Entity.getKeyType("lightbox", attrs, attrsInfo)
        return [
            lightboxType,
            {
                ...attrs.info,
                context: {
                    ...attrs.info.context,
                    e: attrs.lightbox,
                    _e: {
                        ...attrs.info.context._e,
                        e: lightboxType,
                    },
                },
            },
        ]
    }, [])
    const handleLightbox = React.useCallback(() => {
        setLB(true)
    }, [])
    const imgProps = React.useMemo(() => {
        let props = {}
        if (attrs.bg) props.bg = 1
        if (!attrs.lightbox) return props
        return { ...props, onClick: handleLightbox }
    }, [attrs, handleLightbox])
    let Tag = React.Fragment
    let tagArgs = {}
    let imgArgs = htmlAttrs
    if (config?._wrap) {
        Tag = "div"
        const { domRef, inline, _e, ...rest } = htmlAttrs
        tagArgs = { ref: domRef, ...rest, "type-img": "" }
        imgArgs = { inline, _e }
    } else {
        imgArgs["type-img"] = ""
    }
    //const Element = useElement(lightboxType)
    //console.log(args)
    if (!val || val.length === 0) return null
    //console.log("IMAGELIST", newInfo)

    if (carouselInfo) {
        return (
            <Container tag={Carousel} info={info} {...args}>
                <Track>
                    {val.map((img, i) => (
                        <LazyImage key={i} src={img} data-index={i} {...imgProps} />
                    ))}
                </Track>
                {carouselInfo.arrows !== false && <Arrows />}
                {carouselInfo.navigator !== false && <Navigator />}
            </Container>
        )
    }
    //console.log(info)
    return (
        <Tag {...tagArgs}>
            {val.map((src, i) => (
                <LazyImage key={i} src={src} {...imgArgs} {...imgProps} />
            ))}
            {lb && Element && (
                <Portal>
                    <Element info={lightboxInfo} state={state} dispatch={dispatch} />
                </Portal>
            )}
        </Tag>
    )
}
*/
export default ImageList
