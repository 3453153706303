import React from "react"
import { useAdmin } from "./hooks/useAdmin"
//import { useAttrs } from "./hooks/useAttrs.js"
const String = ({ context, _path, fieldPath, fieldEntity, config, text, string, tag, ...props }) => {
    const ref = React.useRef()
    useAdmin(ref, context, _path, fieldPath, fieldEntity, string??text)
    //console.log("String", fieldPath, fieldEntity, context)
    const Tag = tag ?? "div"
    //if (!Tag) return string
    return (
        <Tag ref={ref} {...props} elem-string="" type-string="">
            {string}
            {text}
        </Tag>
    )
    /*const ref = React.useRef()
    const attrs = useAttrs(attr)
    const s = content ?? context?.e ?? ""
    //console.log()
    //console.log(info, content, s, attr)
    if (isContent) return s
    const Tag = tag ?? "div"
    return (
        <Tag ref={ref} elem-string="" {...attrs}>
            {s}
        </Tag>
    )*/
}
export default String
