import React from "react"

const Navigator = ({ domRef, config, context, carousel, state, dispatch, ...args }) => {
    //const carousel = context.carousel
    const goto = carousel?.goto
    const go = React.useCallback(
        e => {
            e.stopPropagation()
            const i = parseInt(e.target.dataset.index)
            if (isNaN(i)) return
            goto(i)
        },
        [goto]
    )
    //console.log(carousel, args, Array.from(Array(carousel.n)))
    if (!carousel) return null
    return (
        <div ref={domRef} navigator="" {...args}>
            {Array.from(Array(carousel.n)).map((k, i) => {
                //console.log(k, i)
                return (
                    <div
                        key={i}
                        active={i === carousel.a ? "" : undefined}
                        onClick={go}
                        data-index={i}
                    />
                )
            })}
        </div>
    )
}
export default Navigator
