import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"

const NodeWrap = ({context, _path, fieldPath, fieldEntity, children, ...props}) => {
    const ref = useRef()


    const newPath = useAdmin(ref, context, _path, fieldPath, fieldEntity, null, "NodeWrap")
    useWidget(ref)

    return (
        <div {...props} ref={ref}  elem-node-wrap="" region-content="">
            <div region-inner="">
{children}
            </div>
        </div>
    )
}
export default NodeWrap
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "region-content": ""
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "region-inner": "",
            "renderChildren": true,
            "_e": {
              "renderChildren": {
                "_type": "bool"
              }
            }
          }
        }
      }
    ]
  }
}
*/
