import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"
import ImageList from "ui/ImageList"

const Header = ({context, _path, fieldPath, fieldEntity, ...props}) => {
    const ref = useRef()


    const newPath = useAdmin(ref, context, _path, fieldPath, fieldEntity, null, "Header")
    useWidget(ref)

    return (
        <header {...props} ref={ref}  elem-header="">
            <ImageList context={context} _path={newPath} inline="1" img={[{"name":"LOGO Aegean Padel Courts-white-2.svg","url":"/upload/element/exprVal/2022/1/21/logo-aegean-padel-courts-white-2-1.svg"}]}/>
        </header>
    )
}
export default Header
/*
{
  "layout": {
    "data": {
      "name": "header"
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "img"
            }
          },
          "name": [
            {
              "name": "LOGO Aegean Padel Courts-white-2.svg",
              "url": "/upload/element/exprVal/2022/1/21/logo-aegean-padel-courts-white-2-1.svg"
            }
          ],
          "attr": {
            "inline": "1"
          }
        }
      }
    ]
  }
}
*/
