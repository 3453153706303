import React from "react"
import NProgress from "nprogress"
import { Transition, TransitionGroup } from "react-transition-group"
import C from "../config"

import { useLiveValue } from "../hooks/useLiveValue"
import { useSubscriptionProvider, publish } from "../hooks/useSubscription"
import { History } from "../history"
import { updateDb } from "../db/db"
//import { elements } from "../tpl"
import { getElements } from "./register"
//import { elementWidgets } from "../tpl/widgets"
import { renderers } from "./index"
import { nodeTypes } from "../tpl/nodeTypes"
//const Empty = () => <React.Fragment />
//const capitalize = str => str[0].toUpperCase() + str.slice(1)

export const updateStyle = style => {
    //console.log("update style", style)
    const links = document.getElementsByTagName("link")
    for (let i in links) {
        const link = links[i]
        if (
            !link ||
            !link.getAttribute ||
            link.getAttribute("rel") !== "stylesheet" ||
            link.href.indexOf(C.BASE) < 0
        )
            continue

        if (link.href.indexOf("css/site") >= 0 && style) link.href = style
    }
}

const reCSS = /\.css$/
/*
const printStorage = async () => {
    const storage = navigator?.storage
    if (!storage) return
    const estimate = await storage.estimate()
    console.log("storage:", estimate)
}*/

const initSW = () => {
    //if (typeof navigator === "undefined") return
    const sw = navigator?.serviceWorker

    requestIdleCallback(() => {
        //updateStore(true, updated)
        if (!window._db) updateDb()
    })
    if (!sw) {
        return
    }
    //printStorage()

    //console.log("INITSW: add service worker listener")
    sw.addEventListener("message", async event => {
        // Optional: ensure the message came from workbox-broadcast-update
        //console.log("SW event", event)
        if (event.data.meta === "workbox-broadcast-update") {
            const { type, key } = event.data.payload //, updated
            if (type === "data") {
                //console.log("SW CALL DATA")
                requestIdleCallback(() => {
                    //updateStore(true, updated)
                    updateDb()
                })
            }
            if (type === "query") {
                //console.log("SW CALL REFRESH", key)
                //Query.refresh(key)
            }
            if (type === "app" && reCSS.test(key)) {
                //console.log("update style", key)
                updateStyle(key)
            }
        }
    })
}

/*import { cleanStore } from "lib/query/cleanStore"
//let cleaner
const useStoreCleaner = (status, location) => {
    const state = React.useRef({ queue: [] })
    React.useEffect(() => {
    const cleanStore = async () => {
            if (!cleaner) cleaner = await import("lib/query/cleanStore")
            cleaner.cleanStore(older, state.current.queue)
        }
        if (status !== "loaded") return
        //if (state.current.currentLocation === location) return
        //state.current.currentLocation = location
        if (state.current.queue.filter(loc => loc === location).length > 0) return
        state.current.queue.push(location)
        if (state.current.queue.length < 3) return
        const older = state.current.queue[0]
        state.current.queue = state.current.queue.slice(1)
        if (older === location) return
        cleanStore(older, state.current.queue)
    }, [location, status])
}*/

const useScrollToTop = (status, node, first) => {
    //console.log("NODE", status)
    const prevState = React.useRef()
    React.useLayoutEffect(() => {
        //console.log("NODE rendered", status)
        //console.log("LAYOUT EFFECT", status, node, first)
        if (prevState.current !== status && status === "entered") {
            publish("title", node?.title ?? "")
        }

        if (status === "entering") {
            requestAnimationFrame(() => {
                const oldNode = document.querySelector('[node-status="exiting"]')
                const newNode = document.querySelector('[node-status="entering"]')
                //console.log(oldNode, newNode)
                if (!first && oldNode) {
                    //const rect = oldNode.getBoundingClientRect()
                    //const oldHeight = `${rect.height}px`
                    //console.log(rect)
                    const oldPage = History.getPrevPage()
                    const oldScroll = window.scrollY
                    //console.log("SCROLL", oldScroll)
                    if (oldPage) oldPage.scroll = oldScroll

                    let sameNode = false
                    if (C.LANGUAGES) {
                        if (
                            oldPage &&
                            node &&
                            node.path &&
                            node.path.filter(path => path.p === oldPage.pathname).length > 0
                        ) {
                            // same node; don't scroll
                            sameNode = true
                        }
                    }

                    const newPage = History.getLocation() //window.history.state
                    const newScroll = sameNode
                        ? oldScroll
                        : newPage && newPage.scroll
                        ? newPage.scroll
                        : 0
                    //console.log("NEW SCROLL", newScroll)
                    if (newNode) {
                        newNode.style.position = "static"
                        newNode.style.transform = "none"
                    }

                    oldNode.style.position = "absolute"
                    oldNode.style.top = 0
                    oldNode.style.left = 0
                    oldNode.style.width = "100%"
                    //oldNode.style.height = oldHeight
                    oldNode.style.transform = `translate(0, ${newScroll - oldScroll}px)`

                    /*if (nodeRef.current) {
                        nodeRef.current.style.position = "relative"
                        nodeRef.current.style.transform = "none"
                        }*/
                    //console.log("SCROLL")
                    window.scrollTo(0, newScroll)
                } else {
                    if (newNode) {
                        newNode.style.position = "static"
                        newNode.style.transform = "none"
                    }
                    /*if (nodeRef.current) {
                        nodeRef.current.style.position = "relative"
                        nodeRef.current.style.transform = "none"
                    }*/
                }
            })
        }

        prevState.current = status
    }, [node, status, first])
}
const EmptyNode = () => null
const Node = ({ context, _path, status, node, first }) => {
    //console.log(first, node)
    const [Tag, args] = React.useMemo(() => {
        if (!node) return [null, null]
        const args = {
            node,
            [node.type]: node,
            context: {
                ...context,
                node,
                //_path: [...context._path, { type: "entity", entity: node }],
            },
            _path
        }
        if (node?._c?._layout) {
            const elements = getElements()
            const name = `Node_${node._id}`
            //console.log("NODE", name, node)
            const widget = elements[name]
            if (widget) return [widget, args]
            //console.log("widget not found")
        }
        //console.log(nodeTypes)
        //console.log("NODE", node.type, node)
        return [nodeTypes[node.type] ?? EmptyNode, args]
    }, [node, context, _path])
    const attrs = React.useMemo(
        () => ({
            "node-status": status,
            ...(first ? { "node-first": "" } : { "node-not-first": "" }),
            ...(node?._c?._class ? { className: node._c._class } : {}),
            ...(node?._c?._cssid ? { id: node._c._cssid } : {}),
        }),
        [node, status, first]
    )
    useScrollToTop(status, node, first)
    //const Element = elements[capitalize(node.type)] ?? Empty
    //console.log("Node", attrs, args)
    return (
        <article {...attrs}>
            <Tag {...args} />
        </article>
    )
}

/*
const queryConfig = { single: true, prio: true }
const pathField = C.LANGUAGES ? "path.p" : "path"
const normalizePath = pathname =>
    pathname === "/index.html" ? "/" : pathname.replace(/\.html$/, "")
export const buildNodeQuery = pathname => {
    const p = normalizePath(pathname)
    return {
        query: {
            $or: [{ [pathField]: p }, { alias: p }],
        },
    }
}
const localState = {
    currentPath: undefined,
    currentUrl: undefined,
    pageReq: {},
    queryCache: {},
    localRetriever: undefined,
    lastStore: undefined,
    retriever: undefined,
}
const doUpdate = (pathname, value) => {
    localState.pageReq[pathname] = Object.keys(value.data)
    for (const k of localState.pageReq[pathname]) {
        localState.queryCache[k] = value.data[k]
        //if (k === "h-2101538931") console.log("U1 h-2101538931", value.data[k].results[1].price)
    }
    //console.log(pathname, currentUrl)
    //window.iconStore = { ...(window.iconStore ?? {}), ...value.icons }
    if (pathname === localState.currentUrl || pathname === "/index.html") {
        for (const q of localState.pageReq[pathname]) {
            window.dataStore[q] = localState.queryCache[q] //{ ...(lastStore ?? {}), ...data }
            localState.lastStore[q] = localState.queryCache[q]
        }
        //console.log("update datastore", data, window.dataStore)
        return true
    }
    return false
}

const refreshAfterUpdate = refreshed => {
    requestIdleCallback(() => {
        if (refreshed) Query.refreshed()
        navigator?.serviceWorker?.controller?.postMessage({
            type: "REFRESH",
            payload: localState.currentUrl,
        })
    })
}
const updateStore = async (refreshQuery, updated) => {
    if (!localState.localRetriever)
        localState.localRetriever = localforage.createInstance({ name: "store" })
    if (updated) {
        let refreshed = false
        for (const pathname of updated) {
            const key = `/store${pathname.replace(".html", ".json")}`
            const value = await localState.localRetriever.getItem(key)
            const didupdate = doUpdate(pathname, value)
            refreshed = refreshed || didupdate
        }
        if (refreshQuery) refreshAfterUpdate(refreshed)
        return
    }
    let refreshed = false
    localState.localRetriever
        .iterate((value, key) => {
            const match = key.match(/^\/store(.*).json$/)
            const pathname = `${match[1]}.html`
            const didupdate = doUpdate(pathname, value)
            refreshed = refreshed || didupdate
        })
        .then(() => {
            if (refreshQuery) refreshAfterUpdate(refreshed)
        })
}

export const retrieveStore = async path => {
    const pathname = path === "/" ? "/index.html" : /\.html$/.test(path) ? path : `${path}.html`
    if (localState.pageReq[pathname]) {
        const data = localState.pageReq[pathname].reduce((acc, q) => {
            acc[q] = localState.queryCache[q]
            return acc
        }, {})
        window.dataStore = { ...(localState.lastStore ?? {}), ...data }
        localState.lastStore = data
        return data
    }
    if (!localState.localRetriever)
        localState.localRetriever = localforage.createInstance({ name: "store" })

    //const p = normalizePath(pathname)
    //const storeFileName = p === "/" ? "/index.json" : `${p}.json`
    const storeFilePath = `/store${pathname.replace(".html", ".json")}`
    let data = await localState.localRetriever.getItem(storeFilePath)
    if (!data) {
        //console.log("FETCH")
        if (!localState.retriever) localState.retriever = axios.create({ baseURL: C.BASE })
        try {
            const res = await localState.retriever.get(storeFilePath)
            data = res.data
        } catch (e) {
            console.log(e)
        }
    }
    if (data) {
        localState.pageReq[pathname] = Object.keys(data.data)
        localState.queryCache = { ...localState.queryCache, ...data.data }
        window.dataStore = { ...(localState.lastStore ?? {}), ...data.data }
        //window.iconStore = { ...(window.iconStore ?? {}), ...data.icons }
        //console.log("RETRIEVED STORE", storeFilePath, data)
        localState.lastStore = data.data
    }
    //console.log("RETRIEVED")
    return data
}
const useNodeRetriever = pathname => {
    //const first = React.useRef()
    //const [loadingStore, setLoadingStore] = React.useState()
    const state = React.useRef({})
    const customNode = React.useMemo(() => {
        if (pathname === "/shell")
            return { _id: "", _custom: true, type: "page", title: "", path: pathname }
        if (customNodes[pathname]) return { ...customNodes[pathname], path: pathname }
        return Object.keys(customNodes).reduce((acc, key) => {
            if (!!acc || !customNodes[key].pattern) return acc
            if (customNodes[key].pattern.test(pathname))
                return { ...customNodes[key], path: pathname }
            return acc
        }, false)
    }, [pathname])
    //console.log(pathname)
    //console.log("NODE RETRIEVER", loadingStore, pathname)
    React.useMemo(() => {
        //if (loadingStore) return
        if (
            C.LANGUAGES &&
            state.current.node &&
            state.current.node.path.filter(path => path.p === pathname).length > 0
        )
            return

        if (customNode) {
            state.current.query = null
        } else {
            //console.log("BUILD NODE QUERY")
            state.current.query = buildNodeQuery(pathname)
        }
    }, [pathname, customNode])
    const [resultNode, status] = useQuery(state.current.query, queryConfig)
    //console.log("NODE RETRIEVER", state.current, resultNode, status)
    if (customNode || status === "loaded") {
        state.current.node = customNode || resultNode
        if (typeof window !== "undefined") {
            requestAnimationFrame(() => {
                NProgress.done()
            })
        }
        return [state.current.node, "loaded"]
    }

    return [state.current.node, status]
}
*/
const getKey = (node, language) => {
    if (!node) return "none"
    return node?._id
    /*const items = []
    if (C?.LANGUAGES?.length > 0) items.push(language ?? C.LANGUAGES[0])
    items.push(node?._id)
    return items.join(":")*/
}

const nodeNotFound = {
    _id: {},
    _custom: true,
    type: "error",
    title: "Pagină inexistentă",
    body: "Pagina nu a fost găsită.",
}

const getNodeIndex = () => (typeof window === "undefined" ? global._db?.node : window._db?.node)

const useNodeLoader = path => {
    const db = useLiveValue(getNodeIndex)
    if (!db?.data) return null
    const id = db?.idx?.path?.[path]
    return id ? db?.data?.[id] : nodeNotFound
}
const NodeTransition = props => {
    const { domRef, context, _path, state, dispatch, children, _e, ...args } = props
    const [location, setLocation] = React.useState(History.getLocation())

    const prevNode = React.useRef()
    const first = React.useRef(true)
    const resultNode = useNodeLoader(location.sanitized)
    const node = resultNode //?? nodeNotFound
    const language = location.language
    const nodeKey = React.useMemo(() => getKey(node, language), [node])
    //console.log(first.current, node)
    const publish = useSubscriptionProvider()
    React.useEffect(() => {
        //currentNode = node
        if (!node) return
        requestAnimationFrame(() => {
            //console.log("NODE PUBLISH NODE", node)
            publish("node", node)
        })
    }, [publish, node])
    React.useMemo(() => {
        if (!node) return
        if(C.isSSR) publish("node", node)
        if (prevNode.current && prevNode.current !== nodeKey) first.current = false
        prevNode.current = nodeKey
    }, [nodeKey])
    const handleLocationChange = React.useCallback(
        async (pathname, search, hash, language, sanitized) => {
            //const t = performance.now()
            try {
                /*localState.currentPath = sanitized
                localState.currentUrl =
                    localState.currentPath === "/"
                        ? "/index.html"
                        : `${localState.currentPath}.html`
                await retrieveStore(sanitized)*/
                //console.log(`Retrieved ${sanitized} in ${performance.now() - t}`)
                //console.log(pathname, search, sanitized)
                setLocation({ pathname, search, hash, language, sanitized })
            } catch (e) {
                console.log("ERROR RETRIEVING STORE", e)
                setLocation({ pathname, search, hash, language, sanitized })
            }
        },
        []
    )
    React.useEffect(() => {
        //localState.lastStore = window.dataStore
        //const url = window.location.pathname
        //localState.currentUrl = url === "/" ? "/index.html" : url
        History.addLocationListener(handleLocationChange)
        window.requestIdleCallback(() => {
            initSW()
            //updateStore()
        })
        return () => History.removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    const { timeout, transitionProps, ...wrapperProps } = args
    const t = timeout ?? 1000
    //const t = 10000
    //console.log(location.hash)
    //console.log(language, location, resultNode, nodeKey)
    React.useEffect(() => {
        NProgress.done()
    }, [nodeKey, language])
    React.useEffect(() => {
        //console.log("NPROGRESS DONE")
        if (nodeKey === "none") return
        window.requestAnimationFrame(() => {
            if (!location.hash) return
            const el = document.getElementById(location.hash.replace("#", ""))
            //console.log(location.hash, el)
            if (el) el.scrollIntoView()
        })
    }, [nodeKey, t, location.hash])
    //console.log("NODEKEY", nodeKey)
    return (
        <TransitionGroup appear={true} enter={true} {...wrapperProps}>
            {nodeKey !== "none" && (
                <Transition key={nodeKey} timeout={t} unmountOnExit={true}>
                    {status => (
                        <Node context={context} _path={_path} status={status} node={node} first={first.current} />
                    )}
                </Transition>
            )}
        </TransitionGroup>
    )
}
export default NodeTransition
