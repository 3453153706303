import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"

const Footer = ({context, _path, fieldPath, fieldEntity, ...props}) => {
    const ref = useRef()


    const newPath = useAdmin(ref, context, _path, fieldPath, fieldEntity, null, "Footer")
    useWidget(ref)

    return (
        <footer {...props} ref={ref}  elem-footer=""/>
    )
}
export default Footer
/*
{
  "layout": {
    "data": {
      "name": "footer"
    }
  }
}
*/
