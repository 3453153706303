import { useRef, useMemo } from "react"
import { useWidget } from "ui/hooks/useWidget"
import { useAdmin } from "ui/hooks/useAdmin"
import NodeTransition from "ui/NodeTransition"
import Header from "./Header"
import Footer from "./Footer"

const Site = ({context, _path, fieldPath, fieldEntity, ...props}) => {
    const ref = useRef()


    const newPath = useAdmin(ref, context, _path, fieldPath, fieldEntity, null, "Site")
    useWidget(ref)

    return (
        <div {...props} ref={ref}  elem-site="">
            <Header context={context} _path={newPath}/>
            <main>
                <NodeTransition context={context} _path={newPath} main-inner=""/>
            </main>
            <Footer context={context} _path={newPath}/>
        </div>
    )
}
export default Site
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Header"
        }
      },
      {
        "data": {
          "name": "main"
        },
        "children": [
          {
            "data": {
              "name": "NodeTransition",
              "attr": {
                "main-inner": "",
                "_e": {
                  "main-inner": {
                    "is": "exprValExt"
                  }
                }
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": "Footer"
        }
      }
    ]
  }
}
*/
