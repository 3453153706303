import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { checkFeatures, loadPolyfill, getRoot } from "./init"
import React from "react"
import ReactDOM from "react-dom"
import App from "./app/App"

const startBugsnag = async () => {
    Bugsnag.start({
        apiKey: "496da11477b82e76d5af86f65e951f96",
        plugins: [new BugsnagPluginReact(React)],

        beforeSend: function (report) {
            const params = new URLSearchParams(window.location.search)
            const fbclidCheck = params.get("fbclid")
            if (
                fbclidCheck &&
                report.errorClass === "TypeError" &&
                report.errorMessage === "Illegal invocation"
            ) {
                report.ignore()
            }
        },
    })
}

const main = () => {
    startBugsnag()
    //console.log(capitalize("main"))
    const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
    const [root, childrenLength] = getRoot()
    //console.log("children:", childrenLength, module.hot)
    //const render = childrenLength === 0 || module.hot ? ReactDOM.render : ReactDOM.hydrate
    const render = childrenLength === 0 ? ReactDOM.render : ReactDOM.hydrate
    //rebuildIndex()

    render(
        <ErrorBoundary>
            <App />
        </ErrorBoundary>,
        root,
        () => {
            window.isHydrating = false
            window.isLoading = false
            //document.body.classList.add("site-loaded")
        }
    )
}

export const boot = () => {
    const features = checkFeatures()
    if (features.length > 0) {
        loadPolyfill(features, main)
    } else main()
}
boot()
//serviceWorker.register();
