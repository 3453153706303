import C from "../../config"
//import { isBrowser, isSSR, DPR } from "../init"

const screenDefault = { state: { W: 0, H: 0, MQ: "xss", O: "portrait", RATIO: "16x9" } }
const screen = typeof window === "undefined" ? screenDefault : window.screenState
const sizes = ["xss", "xs", "sm", "md", "lg", "xl"]
const transform_mimes = ["image/jpeg", "image/png"]

const getBrowser = () => {
    let agent = navigator.userAgent.toLowerCase().match(/(opera|chrome|safari|firefox|msie)/i)
    if (agent) return agent[1]
    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11./i))
        return "msie"
    return "other"
}
const webpNotSupported = ["safari", "msie"]
let supportsWebp = C.isSSR || !webpNotSupported.includes(getBrowser())
const check_webp_feature = (feature, callback) => {
    const kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation:
            "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
    }
    const img = new Image()
    img.onload = () => {
        var result = img.width > 0 && img.height > 0
        //console.log("OK", result)
        callback(feature, result)
    }
    img.onerror = () => {
        //console.log("EROR")
        callback(feature, false)
    }
    img.src = "data:image/webp;base64," + kTestImages[feature]
}

// eslint-disable-next-line
let webpSettled = false
if (C.isBrowser) {
    check_webp_feature("lossless", (feature, result) => {
        supportsWebp = result
        webpSettled = true
    })
}

const getExtension = path => {
    if (!path) return null
    const dot = path.lastIndexOf(".")
    return path.substring(dot + 1).toLowerCase()
}

export const imgPath = (imgurl, imageStyle, imgConfig, screenState) => {
    let aspect = (imgConfig?.w ?? 1) / (imgConfig?.h ?? 1)
    let config = { ...(imgConfig ?? {}), aspect }
    if (!imgurl) return [null, config]
    if (!transform_mimes.includes(config.mime)) return [`${C.BASE}${imgurl}`, config]
    let url = imgurl
    const ext = getExtension(imgurl)
    if (!ext) return [`${C.BASE}${imgurl}`, config]
    //if ((!initDone || supportsWebp) && !config.keepFormat) {
    if (supportsWebp && !config.keepFormat) {
        if (config.mime === "image/jpeg" || config.mime === "image/png") {
            const path = imgurl.substring(0, imgurl.lastIndexOf("."))
            url = `${path}.webp`
        }
    }
    const mq = screenState?.MQ ?? screen?.state?.MQ ?? "lg"
    let W = screenState?.W ?? screen?.state?.W ?? 1200
    if (W === 0) W = 1200
    let H = screenState?.H ?? screen?.state?.H ?? 1200
    if (H === 0) H = 1200
    const indexMq = sizes.indexOf(mq)
    const imgStyle = imageStyle ?? "img"
    let style
    for (const mq of sizes.slice(0, indexMq + 1))
        if (C.styles[imgStyle]?.[mq]) style = C.styles[imgStyle]?.[mq]
    if (!style && C.styles[imgStyle]) style = C.styles[imgStyle][Object.keys(C.styles[imgStyle])[0]]

    if (style) {
        //console.log(imageStyle, style, mq, W, url, supportsWebp)
        const unit = style.unit ?? "w"
        const hunit = style.hunit ?? unit

        let w = style.w
        if (unit === "w") w = Math.ceil((w * W) / 500) * 5
        //console.log(w, dpr)
        if (style.wMax && w > style.wMax) w = style.wMax
        w = w * C.DPR

        let h = style.h ?? 0
        if (hunit === "w") h = Math.ceil((h * W) / 500) * 5
        if (hunit === "h") h = Math.ceil((h * H) / 500) * 5
        h = h * C.DPR

        const quality = style.q ?? 80

        if (h > 0) {
            if (style.fit)
                return [
                    `${C.BASE}/image/w_${w},h_${h},f_${style.fit};q_${quality}${url}`,
                    style.fit === "inside" || style.fit === "outside"
                        ? { ...config, w, h }
                        : { ...config, aspect: w / h, w, h },
                ]
            let wCalc = Math.min(w, config.w)
            let hCalc = wCalc / config.aspect
            if (hCalc > h) {
                hCalc = h
                wCalc = hCalc * config.aspect
            }
            return [
                `${C.BASE}/image/w_${w},h_${h};q_${quality}${url}`,
                { ...config, w: wCalc, h: hCalc },
            ]
        } else {
            let wCalc = Math.min(w, config.w)
            let hCalc = wCalc / config.aspect

            return [`${C.BASE}/image/w_${w};q_${quality}${url}`, { ...config, w: wCalc, h: hCalc }]
        }
    }
    if (url === imgurl) return [`${C.BASE}${url}`, config]
    return [`${C.BASE}/image${url}`, config]
}

const O = {
    landscape: "l",
    portrait: "p",
}
export const selectArt = (src, screen, svg) => {
    //console.log("selectArt", src, screen, svg)
    if (!src) return [null, {}]
    if (svg || src.mime === "image/svg+xml") return [src.url, { mime: "image/svg+xml" }]
    //console.log(src)
    if (!src.srcset) return [src.url, src]
    //console.log(src, screen)
    const srcset = src.srcset.filter(
        item =>
            (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
            (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
            (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
    )

    if (srcset.length === 0) return [src.url, src]
    return [srcset[0].src, srcset[0]]
}
export const getImageUrl = (src, imageStyle) => {
    const [imgurl, imgConfig] = selectArt(src, screen)
    //console.log(src, imageStyle, screen, imgurl, imgConfig)
    return imgPath(imgurl, imageStyle, imgConfig, screen)[0]
}
