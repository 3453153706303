import {handlers} from "conf/widgets"

let init = false
let register = {}
let hIndex = 0

let lastEv
let lastEvScroll
let lastTS
let lastScroll
let lastDir
let iter
let wh, ww
const refreshInt = 200
const update = (id, scroll) => {
    //console.log("REFRESH BOUNDING")
    const rect = register[id].elem.getBoundingClientRect()
    const top = rect.top + scroll
    register[id].top = rect.top + scroll
    register[id].height = rect.height
}
const refresh = () => {
    if(iter) 
        return scheduleRefresh()
    //console.log("REFRESH")
    wh = window.innerHeight
    ww = window.innerWidth
    const scroll = window.scrollY
    Object.keys(register).forEach(id => {
        if(typeof register[id].top === "undefined") 
            return update(id)
        if(scroll<register[id].top-wh || scroll>register[id].top+register[id].height) return
        update(id, scroll)
    })
    scheduleRefresh()
}
const scheduleRefresh = () => {
    setTimeout(refresh, refreshInt)
}

const scrollIter = ts => {
    //console.log("ITER TS", ts, lastTS)
    if(ts===lastTS) return
    //if(lastTS>0)
    //    console.log("DELTA", ts-lastTS)
    lastTS = ts
    const scroll = window.pageYOffset
    //console.log("ITER SCROLL", scroll, lastScroll, lastEvScroll)
    if(lastScroll!==scroll) {
        lastScroll = scroll
        if (scroll>lastEvScroll) {
            lastDir = 1
        } else {
            lastDir = 0
        }
        Object.values(register).forEach(h => {
            //console.log(h)
            if(scroll<h.top-wh || scroll>h.top+h.height) return
            //console.log(handler)
            const f = handlers[h.h]
            //if(h) h(scroll, dir, elem, top, height, wh)
            if(f)
                f(h.elem, lastScroll, lastDir, h.top, h.height, wh, ww)
        })
    } else {
        if(ts-lastEv>50) {
            //console.log("ITER STOP")
            iter = false
            lastTS = 0
            return
        }
    }
    requestAnimationFrame(scrollIter)
}

const handleScroll = e => {
    lastEv = performance.now()
    lastEvScroll = window.scrollY
    //console.log("EV", lastEv, lastEvScroll)
    if(!iter) {
        iter = true
        requestAnimationFrame(scrollIter)
    }
}

//export const addScrollHandler = (h, ...args) => {
export const addScrollHandler = (elem, h) => {
    const index = hIndex
    register[index] = {h, elem}
    hIndex++
    if(!init) {
        //window.addEventListener("scroll", handleScroll, { capture: false, passive: true })
        window.addEventListener("scroll", handleScroll)
        scheduleRefresh()
        init = true
    }
    return index
}
export const removeScrollHandler = index => {
    delete register[index]
}

//return () => window.removeEventListener("scroll", handleScroll)

