import React from "react"
import { useScreen } from "../hooks/useScreen"
import { useUnmounted } from "../hooks/useUnmounted"
import { useHotkey } from "../hooks/useHotkey"
/*
const carouselType = {
    is: "record",
    label: "Carousel",
    keys: {
        n: { is: "int", label: "Număr elemente" },
        a: { is: "int", label: "Activ" },
        p: { is: "int", label: "Precedent Activ" },
        dir: { is: "string", label: "Direcție" },
        nv: { is: "int", label: "Număr elemente vizibile" },
        next: { is: "fun", label: "Next" },
        prev: { is: "fun", label: "Prev" },
        goto: { is: "fun", label: "Go" },
    },
}*/

const Carousel = ({
    config,
    autorun,
    timeout,
    context,
    domRef,
    state,
    dispatch,
    children,
    ...args
}) => {
    const unmounted = useUnmounted()
    const localRef = React.useRef()
    const ref = domRef ?? localRef
    //console.log(autorun, args)
    const [carouselState, setCarouselState] = React.useState({
        n: 0,
        a: config?.active??0,
        p: 0,
        dir: "right",
        nv: 1,
    })
    const n = carouselState.n
    const next = React.useCallback(e => {
        if(e) e.stopPropagation()
        setCarouselState(state => {
            if (state.n < state.nv + 1) return state
            const p = state.a
            const a = (state.a + 1) % state.n
            const dir = "left"
            return { ...state, p, a, dir }
        })
    }, [])
    React.useEffect(() => {
        if (!autorun) return
        //console.log(autorun)
        let t = setInterval(() => {
            next()
        }, autorun * 1000)
        const vc = () => {
            if (document.visibilityState === 'visible') {
                setInterval(() => {
                    next()
                }, autorun * 1000)
            } else {
                clearInterval(t)
                t = 0
            }
        }
        document.addEventListener("visibilitychange", vc)
        return () => {
            if(t)
                clearInterval(t)
            document.removeEventListener("visibilitychange", vc)
        }
    }, [autorun, next])
    const prev = React.useCallback(e => {
        if(e) e.stopPropagation()
        setCarouselState(state => {
            if (state.n < state.nv + 1) return state
            const p = state.a
            const a = state.a === 0 ? state.n - 1 : state.a - 1
            const dir = "right"
            return { ...state, p, a, dir }
        })
    }, [])
    const goto = React.useCallback(a => {
        setCarouselState(state => {
            const p = state.a
            const dir =
                p < a
                    ? a - p < p + state.n - a
                        ? "left"
                        : "right"
                    : p - a > a + state.n - p
                    ? "left"
                    : "right"
            return { ...state, p, a, dir }
        })
    }, [])
    const home = React.useCallback(() => {
        goto(0)
    }, [])
    const end = React.useCallback(() => {
        goto(n-1)
    }, [n])
    const update = React.useCallback(update => {
        setCarouselState(state => ({ ...state, ...update }))
    }, [])
    const carousel = React.useMemo(
        () => ({
            ...carouselState,
            next,
            prev,
            goto,
            update,
            ref,
        }),
        [carouselState, prev, next, goto, update, ref]
    )
    useHotkey("ArrowRight", next)
    useHotkey("ArrowLeft", prev)
    useHotkey("ArrowDown", next)
    useHotkey("ArrowUp", prev)
    useHotkey("PageDown", next)
    useHotkey("PageUp", prev)
    useHotkey("Home", home)
    useHotkey("End", end)
    const newContext = React.useMemo(
        () => ({
            ...context,
            carousel,
        }),
        [context, carousel]
    )

    const carouselProps = React.useMemo(() => {
        return {
            carousel: "",
            style: {
                "--n-items": carouselState.n,
            },
        }
    }, [carouselState])
    const getVisible = React.useCallback(() => {
        if (!ref.current) return
        const width = ref.current.getBoundingClientRect().width
        //console.log(width)
        let nv = parseInt(window.getComputedStyle(ref.current)?.getPropertyValue("--n-visible"))
        //console.log(nv)
        if (isNaN(nv)) {
            nv = 1
            requestAnimationFrame(() => {
                if (unmounted.current) return
                getVisible()
            })
        }
        const itemWidth = width / nv

        //console.log(itemWidth, nv)
        setCarouselState(state => ({
            ...state,
            width,
            nv,
            itemWidth,
        }))
    }, [ref, unmounted])
    useScreen(getVisible)
    React.useLayoutEffect(() => {
        getVisible()
    }, [getVisible])

    return (
        <div ref={ref} {...args} {...carouselProps}>
            {children(context, carousel, state, dispatch)}
        </div>
    )
}

export default Carousel
