import Header from "./Header"
import Site from "./Site"
import Footer from "./Footer"
import Homepage from "./Homepage"
import NodeWrap from "./NodeWrap"
import Slideshow from "./Slideshow"
import Block from "./Block"
import {registerElements} from "ui/register"
const elements = { Header, Site, Footer, Homepage, NodeWrap, Slideshow, Block }
const elementTypes = { homepage: Homepage, slideshow: Slideshow, block: Block }
registerElements(elements, elementTypes)
export { Header, Site, Footer, Homepage, NodeWrap, Slideshow, Block }
