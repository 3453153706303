import React from "react"
import {addScrollHandler, removeScrollHandler} from "scroll/scroll"
/*
const handleScroll = (scroll, dir, handler, elem, top, height, wh) => {
    if(scroll<top-wh || scroll>top+height) return
    console.log(handler)
    const h = handlers[handler]
    if(h) h(scroll, dir, elem, top, height, wh)
}
*/

export const useWidget = (ref, config) => {
    React.useEffect(() => {
        let scrollId
        if(!ref.current) return
        if(!config?._handlers) return
        if(config._handlers.onScroll) {
            //const rect = ref.current.getBoundingClientRect()
            //const top = rect.top + window.scrollY
            //scrollId = addScrollHandler(handleScroll, config._handlers.onScroll, ref.current, top, rect.height, window.innerHeight)
            scrollId = addScrollHandler(ref.current, config._handlers.onScroll)
        }
        //console.log("widget", config?._handlers)
        return () => {
            if(scrollId)
                removeScrollHandler(scrollId)
        }
    }, [ref, config])
}
