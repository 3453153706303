import React from "react"

let handlers

const handleKeyUp = e => {
    //console.log(e.key)
    const cb = handlers?.[e.key]
    if (!cb || cb.length === 0) return
    e.stopPropagation()
    e.preventDefault()
    //cb[cb.length-1]()
    cb[0]()
    /*cb.forEach(h => {
        h()
    })*/
}

export const useHotkey = (hotkey, cb) => {
    React.useEffect(() => {
        const key = hotkey
        const handler = cb

        if (!handlers) {
            handlers = {}
            document.addEventListener("keyup", handleKeyUp)
        }

        if (!handlers[key]) handlers[key] = []
        //handlers[key].push(handler)
        handlers[key] = [...handlers[key], handler]
        return () => {
            if (!handlers[key]) return
            handlers[key] = handlers[key].filter(h => h !== handler)
            if (handlers[key].length === 0) delete handlers[key]
        }
    }, [hotkey, cb])
}
