import axios from "axios"

const WEBSITE_NAME="Padel"
const PROTO = "https"
const HOST = "aegeanpadelcourts.com"
const BASE = "https://aegeanpadelcourts.com"
const ORIGIN = typeof window !== "undefined" && window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : BASE
const API = "/api"
const auth0 = "Myjd63VKWAOQQU69ZIKKVTqeGYIXBcdE"
const auth0_domain = "dev-g7kz46-h.us.auth0.com"
const auth0_namespace = "https://linuxship.ro/authorization/"
const auth0_api = "https://linuxship.ro/api"
axios.defaults.baseURL = "https://aegeanpadelcourts.com/api/"
axios.defaults.timeout = 30000
axios.defaults.maxcontentLength = 52428890
const styles = {"upload-thumb":{"xss":{"w":128,"h":128,"unit":"px","fit":"contain"}},"og":{"xss":{"w":1200,"h":1200,"unit":"px","keepFormat":true}},"thumb":{"xss":{"w":160,"h":160,"unit":"px","fit":"contain"}},"thumb2":{"xss":{"w":480,"h":480,"unit":"px","fit":"contain"}},"img":{"xss":{"w":100,"wMax":1400,"q":90}},"slide":{"xss":{"w":100,"h":100,"hunit":"h","fit":"cover","q":90}},"imgxl":{"xss":{"w":100,"wMax":2000,"q":90}},"imghalf":{"xss":{"w":100,"q":90},"sm":{"w":50,"q":90}}}
const defaultImageStyle = "img"
const isSSR = typeof window === "undefined"
const notBot = !isSSR && "onscroll" in window && !/(gle|ing)bot/.test(navigator.userAgent)
const isBot = !notBot
const isBrowser = !isSSR && notBot
const DPR = isBrowser ? window.devicePixelRatio : 1
const isHydrating = () => isBrowser && window.isHydrating
const LANGUAGES = null

export default {
    WEBSITE_NAME,
    HOST,
    PROTO,
    ORIGIN,
    BASE,
    API,
    auth0,
    auth0_domain,
    auth0_namespace,
    auth0_api,
    styles,
    defaultImageStyle,
    isSSR, 
    notBot, 
    isBot, 
    isBrowser, 
    DPR, 
    isHydrating,
    LANGUAGES
}
